import axios, { AxiosInstance, AxiosResponse } from 'axios'
import i18next from 'i18next'

let http: AxiosInstance
class APIProvider {
  constructor() {
    http = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    })

    http.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'

    const token = localStorage.getItem('token')
    if (token) {
      http.defaults.headers.common['Authorization'] = `Bearer ${token}`
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }

    const authInterceptor = (response: AxiosResponse) => {
      const token = localStorage.getItem('token')
      if (token) {
        response.headers.Authorization = `Bearer ${token}`
      } else {
        console.log('token not found')
      }
      return response
    }

    axios.interceptors.response.use(authInterceptor, function (error) {
      return Promise.reject(error)
    })

    http.interceptors.request.use(function (config: any) {
      config.headers['Accept-Language'] = i18next.language
      return config
    })

    http.interceptors.response.use(authInterceptor, async function (error) {
      return Promise.reject(error)
    })
  }

  login(data: any) {
    return http.post('auth/login', data)
  }

  get(path: string, query?: any) {
    return http.get(path, {
      params: query,
    })
  }

  post(path: string, data: any, query?: any) {
    return http.post(path, data, {
      params: query,
    })
  }

  update(path: string, data: any, query?: any) {
    return http.put(`${path}`, data, {
      params: query,
    })
  }

  delete(path: string, data?: any, query?: any) {
    return http.delete(`${path}`, data)
  }
}

export default new APIProvider()
