import { PureComponent } from 'react'
import {
  CButton,
  CCol,
  CContainer,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilMagnifyingGlass } from '@coreui/icons-pro'

interface IProps {}

class Page500 extends PureComponent<IProps, {}> {
  render() {
    return (
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <span className="clearfix">
              <h3 className="float-left display-3 me-4">500</h3>
              <h4 className="pt-3">Houston, we have a problem!</h4>
              <p className="text-muted float-left">
                The page you are looking for is temporarily unavailable.
              </p>
            </span>
            <CInputGroup className="input-prepend">
              <CInputGroupText>
                <CIcon icon={cilMagnifyingGlass} />
              </CInputGroupText>
              <CFormInput type="text" placeholder="What are you looking for?" />
              <CInputGroupText>
                <CButton color="primary">Search</CButton>
              </CInputGroupText>
            </CInputGroup>
          </CCol>
        </CRow>
      </CContainer>
    )
  }
}

export default Page500
