import { lazy, Suspense } from 'react'

import { Route, Routes } from 'react-router-dom'
import { FallbackLoading } from './components/LoadingSpinner'
import Login from './views/auth/Login'
import Page404 from './views/pages/Page404'
import Page500 from './views/pages/Page500'

import './scss/style.scss'
import 'moment/locale/fr'

import { ROUTES } from 'layout/routes'

const DefaultLayout = lazy(() => import('./layout/TheLayout'))

const App = () => {
  return (
    <Suspense fallback={<FallbackLoading />}>
      <Routes>
        <Route path={ROUTES.login} element={<Login />}></Route>
        <Route path="/404" element={<Page404 />}></Route>
        <Route path="/500" element={<Page500 />}></Route>
        <Route path="*" element={<DefaultLayout />}></Route>
      </Routes>
    </Suspense>
  )
}
export default App
