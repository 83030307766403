import i18next from 'i18next'
import translation from './fr/translation.json'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const enJson = require('./en/translation.json')
const frJson = require('./fr/translation.json')

export const resources = {
  en: {
    translation: enJson,
  },
  fr: {
    translation: frJson,
  },
} as const

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    //lng: 'en',
    debug: true,
    fallbackLng: ['en', 'fr'],
    saveMissing: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  })
