export const ROUTES = {
  home: '/',
  login: '/login',
  dashboard: '/dashboard',

  users: '/users',
  userDetail: '/users/:id',

  products: '/products',
  productDetail: '/products/:id',

  suppliers: '/suppliers',
  supplierDetail: '/suppliers/:id',

  customers: '/customers',
  customerDetail: '/customers/:id',

  warehouses: '/warehouses',
  stock: '/stock',
  bags: '/bags',
  warehouseDetail: '/warehouses/:id',

  transporters: '/transporters',
  transporterDetail: '/transporters/:id',

  transactions: '/transactions',
  transactionDetail: '/transactions/:id',

  purchases: '/purchases',
  purchaseDetail: '/purchases/:id',

  deliveries: '/deliveries',
  deliveryDetail: '/deliveries/:id',

  sales: '/sales',
  saleDetail: '/sales/:id',

  shipments: '/shipments',
  shipmentDetail: '/shipments/:id',

  collaterals: '/collaterals',
  collateralDetail: '/collaterals/:id',

  institutions: '/institutions',
  institutionDetail: '/institutions/:id',

  accounts: '/accounts',
  accountDetail: '/accounts/:id',

  supplierReport: '/reports/suppliers',
  deliveryReport: '/reports/deliveries',
  customerReport: '/reports/customers',

  clientReport: '/reports/clients',
  warehouseReport: '/reports/warehouses',
  reportDetail: '/reports/:reportId',

  settings: '/settings',
}
