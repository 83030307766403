import { SetState } from 'zustand'
import { ROUTES } from '../layout/routes'
import APIProvider from '../utils/APIProvider'
import { handleAxiosError } from '../utils/error'

export type AuthState = {
  login: (values: { email: string; password: string }) => Promise<void>
  getMe: () => Promise<void>
  loading: boolean
  token: string | null
  authUser: any
  currentAgent: any
  logout: (navigate: any) => Promise<void>
}

const defaultState: any = {
  loading: false,
  token: null,
  authUser: null,
  currentAgent: null,
}

export const AuthStore = (set: SetState<AuthState>) => ({
  ...defaultState,
  login: async (values: { email: string; password: string }) => {
    set({ loading: true })
    //console.log("values", values)
    try {
      const res = await APIProvider.login(values)
      const { user, token } = res.data
      set({
        loading: false,
        token: token,
        authUser: user,
      })
      localStorage.setItem('token', res.data.token)

      window.location.href = ROUTES.dashboard
    } catch (error) {
      set({ loading: false })
      handleAxiosError(error)
    }
  },
  getMe: async () => {
    set({ loading: true })
    try {
      const res = await APIProvider.get(`/users/me`)
      set({
        loading: false,
        authUser: res.data,
      })
    } catch (error) {
      set({ loading: false })
      handleAxiosError(error)
    }
  },
  logout: (navigate: any) => {
    console.log('AuthStore - logout')
    localStorage.clear()
    set(
      {
        token: null,
        authUser: null,
        currentAgent: null,
      },
      true,
    )
    window.location.href = ROUTES.login
  },
})
